<template>
  <v-app v-if="isInited()">
    <!-- 左侧导航栏及左侧顶部的logo和文字 -->
    <v-navigation-drawer v-model="showDrawer" app>
      <!-- 顶部logo及文字，应与appbar顶部保持一致的样式 -->
      <v-app-bar color="primary" dense>
        <div class="d-flex align-center">
          <v-img class="shrink mr-2" contain src="static/eplant-website-favicon-white.png" transition="scale-transition"
            width="32" />

          <div class="text-h4 white--text" v-text="ctrlInfo && ctrlInfo.siteName" />
        </div>
      </v-app-bar>
      <!-- 导航菜单 -->
      <v-list nav dense>
        <NavMenuItem v-for="(it, index) in menu" v-bind:key="index" :item="it" :lv="0" />
      </v-list>
      <!-- 聊天控制面板 -->
      <ChatCtrl v-if="account?.id" @select="onChatSelect" />
    </v-navigation-drawer>

    <!-- 顶部导航栏 -->
    <v-app-bar app color="primary" dense>
      <!-- 唤出菜单按钮，仅小屏幕模式使用 -->
      <v-app-bar-nav-icon v-if="!showDrawer" @click.stop="showDrawer = !showDrawer" />
      <!-- 一系列按钮 -->
      <v-btn v-if="activeRouteMeta" :color="mainMode === 'menu' ? 'info' : 'primary'" class="text-h5 white--text"
        @click="mainMode = 'menu'">
        <v-icon v-if="activeRouteMeta.menuIcon">{{ activeRouteMeta.menuIcon }}</v-icon>
        {{ activeRouteMeta.menuText }}
      </v-btn>
      <v-spacer></v-spacer>
      <!-- 消息按钮 -->
      <v-btn v-if="account?.id" :color="mainMode === 'chat' ? 'info' : 'primary'" class="text-h5 white--text"
        @click="mainMode = 'chat'">
        <v-icon>mdi-chat</v-icon>消息
      </v-btn>

      <!-- 登录 / 个人信息 按钮 -->
      <v-btn color="primary" @click="loginBtnClickEvt++">
        <v-icon>mdi-account</v-icon>
        <span v-if="!account?.id">登录</span>
        <span v-else>{{ account.username }}</span>
      </v-btn>
    </v-app-bar>

    <!-- 登录弹窗组件，初始化后即加载，是否显示由组件内控制 -->
    <Login :clickEvt="loginBtnClickEvt" />

    <!-- 登录后main区域 -->
    <v-main v-if="account?.id">
      <!-- 路由导航控制 -->
      <router-view v-show="mainMode === 'menu'" style="height: 100%" />
      <ChatMain v-show="mainMode === 'chat'" v-if="activeRoomId" :roomId="activeRoomId" style="height: 100%" />
    </v-main>
  </v-app>
</template>

<script>
import store from '../store'
import router from '../router'
import { routes } from '../router/routes.js'
import { mapState } from 'pinia'
import { useSessionStore } from '../store/session'
import { useSysStore } from '../store/sys'
import Login from './login'

import NavMenuItem from './menu-item'
import ChatCtrl from './chatCtrl.vue'
import ChatMain from './chatMain.vue'

export default {
  name: 'App',
  computed: {
    ...mapState(useSessionStore, ['account', 'operations']),
    ...mapState(useSysStore, ['ctrlInfo']),
  },
  components: {
    NavMenuItem,
    Login,
    ChatCtrl,
    ChatMain,
  },

  data: () => ({
    /** main区域控制 */
    // 登录后主区域模式：menu - 菜单导航；chat - 聊天
    mainMode: 'menu',
    /** 左侧抽屉 */
    // 开关，由v-navigation-drawer元素控制。初始值必须给null，给false会导致bug
    showDrawer: null,
    // 菜单项目
    menu: [],
    /** 登录窗口 */
    // 按钮点击事件，用于login子组件感知点击事件
    loginBtnClickEvt: 0,
    /** 当前激活的信息 */
    // 激活的路由
    activeRouteMeta: null,
    // 激活的聊天室ID
    activeRoomId: null
  }),
  watch: {
    // 系统信息变更时，更新浏览器标题
    ctrlInfo: {
      immediate: true,
      deep: true,
      handler(to) {
        if (to && to.siteName) document.title = to.siteName
      }
    },
    // 操作集更新时，重构菜单
    operations() {
      this.menuBuild()
    },
    // 触发store中的isBigScreen变化
    showDrawer(isShow) {
      store.session.renewIsBigScreen(isShow)
    },
    // 路由选择变更时，更新标题栏
    $route: {
      immediate: true,
      handler(to) {
        this.mainMode = 'menu'
        this.activeRouteMeta = to.meta
      }
    },
  },
  mounted() {
    this.menuBuild()
  },
  methods: {
    // 构建菜单项目
    menuBuild() {
      const menu = []
      const rsFmt = (rs, arry) => {
        for (const r of rs) {
          // 如果没有meta.menuText，跳过
          if (!r.meta.menuText) continue
          // 若check检查未通过，跳过
          const checkOk = router.checkIsPass(r)
          if (!checkOk) continue

          /* 如果有下级菜单 */
          if (r.children) {
            // 根据children项整理出可用的，放在subs数组
            r.subs = []
            rsFmt(r.children, r.subs)
            // 如果整理完啥都没有，跳过该项
            if (r.subs.length === 0) continue
          }

          // 添加到arry数组
          arry.push(r)
        }
      }
      rsFmt(routes, menu)
      this.menu = menu
    },
    // 选定了聊天对象
    onChatSelect(evt) {
      // 切换到聊天模式
      this.mainMode = 'chat'
      // 分两次写入，以实现组件刷新
      this.activeRoomId = null
      setTimeout(() => {
        this.activeRoomId = evt.roomId
      }, 50)
    },
    // 是否初始化完毕
    isInited() {
      if (
        this.ctrlInfo &&
        this.account
      ) return true
    }
  },
}
</script>
