module.exports = {
    show: { zh: '密度/质量浓度' },
    combx: {
        mass: 1,
        volume: -1
    },
    units: {
        'kg/m3': {
            sign: 'kg/m\u00b3',
            show: { zh: '千克每立方米' }
        },
        'g/L': {
            show: { zh: '克每升' }
        },
        'mg/L': {
            show: { zh: '毫克每升' },
            combx: {
                mass_mg: 1,
                volume_L: -1
            }
        },
        'ug/L': {
            sign: 'μg/L',
            show: { zh: '微克每升' },
            combx: {
                mass_ug: 1,
                volume_L: -1
            }
        },
        'ppm': {
            sign: 'μg/L',
            show: { zh: '微克每升' },
            combx: {
                mass_ug: 1,
                volume_L: -1
            }
        }
    }
}