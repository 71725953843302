module.exports = {
    show: { zh: '质量' },
    units: {
        kg: {
            show: { zh: '千克' },
        },
        g: {
            show: { zh: '克' },
            a: 0.001,
        },
        mg: {
            show: { zh: '毫克' },
            a: 0.000001,
        },
        t: {
            show: { zh: '吨' },
            a: 1000,
        },
        ug: {
            sign: 'μg',
            show: { zh: '微克' },
            a: 0.000000001,
        }
    }
}