/** ========== 该脚本前后端均加载 ========== */

/** 数据结构定义字典 */
const dict_structure = {
    freeList: {
        label: '自由列表',
        // 不定义任何tag项目
        tagDict: {},
        // 未知tag进行创建
        tagUnknow: 'create',
    },
    ovpnStatus: {
        label: 'OpenVPN连接状态',
        autoOnly: true,
        tagDict: {
        },
        // 未知点抛弃
        tagUnknow: 'drop',
    },
    mqttStatic: {
        label: 'Mqtt连接状态',
        autoOnly: true,
        tagDict: {
        },
        tagUnknow: 'drop',
    }
}

/** 数据通讯协议字典 */
const dict_protocol = {
    // mqtt协议
    mqtt: {
        subs: {
            // 研华ECU网关
            advantechEcu: {
                label: 'MQTT - 研华JSON数据报'
            },
            // NodeRed入口
            noderedEntry: {
                label: 'MQTT - NodeRed数据入口'
            }
        }
    },
    // http协议
    http: {
        subs: {
            // 
            uploadJson: {
                label: 'Http - 标准Json格式'
            },
            // NodeRed入口
            noderedEntry: {
                label: 'Http - NodeRed数据入口'
            }
        }
    },
}

/** 终端类型字典 */
const dict_edgeType = {
    advantech_ecu: {
        label: '研华ECU系列网关',
        protocolDict: {
            'mqtt.advantechEcu': {
                structures: [
                    'freeList'
                ],
            }
        },
    },
    eplant_edge: {
        label: 'Esimu网关',
        protocolDict: {
            'http.uploadJson': {
                structures: [
                    'freeList'
                ],
            },
        },
    },
    nodered_plugin: {
        label: 'NodeRed插件',
        protocolDict: {
            'http.uploadJson': {
                structures: [
                    'freeList'
                ],
            },
        },
    },
    io_virtual: {
        label: '通讯接口',
        protocolDict: {
            'http.uploadJson': {
                structures: [
                    'freeList'
                ],
            },
            'mqtt.noderedEntry': {
                structures: [
                    'freeList'
                ],
            }
        },
    },
    cloudStatic: {
        label: '服务端统计',
        autoOnly: true,
        // 不需要外部传输协议定义，服务端内部调用grpc接口
        protocolDict: {},
    }
}

/** 可自动创建的数据集，数据结构参照mysql-instance */
const auto_instance = {
    _ovpnStatus: {
        remark: 'OpenVPN连接状态',
        structure: 'ovpnStatus',
    }
}

/** 可自动创建的终端 */
const auto_edge = {
    // 服务端统计各ovpn连接的统计
    _ovpnStatus: {
        // 强制属性，结构参照mysql-edge
        constProp: {
            remark: 'OpenVPN 状态',
            typ: 'cloudStatic',
        },
        // 自动创建数据集的类别
        insStructure: 'ovpnStatus'
    }
}

/** 写入自动属性方法 */
function _autoFill(record, autoInfo) {
    // 如果没有自动写对象，直接反馈
    if (!autoInfo) return

    // 有自动填充，写值
    for (const key of Object.keys(autoInfo)) {
        record[key] = autoInfo[key]
    }

    // 标记为自动写
    record.isAuto = true
}
// 自动构建 - 设备
function autoFillEdge(record) {
    _autoFill(record, auto_edge[record.id]?.constProp)
}
// 自动构建 - 数据集
function autoFillIns(record) {
    // 如果设备层级规定了数据集类别，在此处写入
    const insStructure = auto_edge[record.edgeId]?.insStructure
    if (insStructure) _autoFill(record, { structure: insStructure })
}

function getProtocolObject(protocol) {
    const [pMain, pSub] = (protocol || '').split('.')
    if (pMain && pSub) return dict_protocol[pMain]?.subs[pSub] || null
}
function getEdgeTypeObject(typ) {
    return dict_edgeType[typ] || null
}
function getStructureObject(structure) {
    return dict_structure[structure] || null
}

module.exports = {
    auto_instance,
    auto_edge,
    autoFillEdge,
    autoFillIns,
    dict_edgeType,
    dict_protocol,
    dict_structure,
    getProtocolObject,
    getEdgeTypeObject,
    getStructureObject,
}