module.exports = {
    show: { zh: '电阻' },
    combx: {
        voltage: 1,
        current: -1
    },
    units: {
        Ohm: {
            show: { zh: '欧姆' },
            sign: 'Ω'
        },
        kOhm: {
            show: { zh: '千欧姆' },
            sign: 'kΩ',
            a: 1e3
        },
        MOhm: {
            show: { zh: '兆欧姆' },
            sign: 'MΩ',
            a: 1e6
        },
    }
}