import Home from '../views/Home.vue'
import EmptyMain from '../frame/emptyMain.vue'

// 懒加载方法
function lazyLoad(path) {
    return () => import(/* webpackChunkName: "v-[request]" */ `@/${path}.vue`);
}

// 输出的路由
const routes = [
    {
        path: '/',
        meta: {
            menuIcon: 'mdi-home',
            menuText: '首页'
        },
        component: Home
    },
    {
        path: '/das/_das',
        meta: {
            menuIcon: 'mdi-progress-upload',
            menuText: '数据采集',
            checkOp: ['edge:get']
        },
    },
    {
        path: '/dataView/_index',
        meta: {
            menuIcon: 'mdi-chart-areaspline',
            menuText: '数据视图',
            checkOp: ['dataView:get']
        },
    },
    {
        path: '/dataView/_index',
        meta: {
            menuIcon: 'mdi-alarm-light-outline',
            menuText: '事件报警',
            checkOp: ['dataView:get']
        },
    },
    {
        path: '/remote',
        component: EmptyMain,
        meta: {
            menuIcon: 'mdi-remote-desktop',
            menuText: '远程连接',
        },
        children: [
            {
                path: 'statusOfMqttClient',
                meta: {
                    menuIcon: 'mdi-alpha-m-box-outline',
                    menuText: 'MQTT 客户端状态',
                    checkOp: ['edge:get']
                }
            },
            {
                path: 'openvpn',
                meta: {
                    menuIcon: 'mdi-vpn',
                    menuText: 'OpenVPN 客户端状态',
                    checkOp: ['edge:get']
                }
            },
        ]
    },
    {
        path: '/nodered',
        component: lazyLoad('components/iframe'),
        props: {
            src: '/nodered/'
        },
        meta: {
            menuIcon: 'mdi-relation-one-to-zero-or-one',
            menuText: '流程组态',
            checkOp: ['nodered:user']
        },
    },
    {
        path: '/users/_users',
        meta: {
            menuIcon: 'mdi-account-multiple-outline',
            menuText: '用户',
        },
    },
    {
        path: '/baiduSugar/list',
        meta: {
            menuIcon: '',
            menuText: 'BI大屏',
            checkOp: ['baiduSugar:get']
        },
    },
    {
        path: '/edge/rtsp',
        meta: {
            menuIcon: '',
            menuText: '视频监控',
            checkOp: ['rtsp:get']
        },
    },
    {
        path: '/sys',
        component: EmptyMain,
        meta: {
            menuIcon: 'mdi-cog-outline',
            menuText: '平台管理',
            checkOp: ['account:logout']
        },
        children: [
            // {
            //     path: 'account',
            //     meta: {
            //         menuIcon: 'mdi-account',
            //         menuText: '账号与角色',
            //         checkOp: ['account:add']
            //     }
            // },
            {
                path: 'info',
                meta: {
                    menuIcon: 'mdi-information-outline',
                    menuText: '基本信息',
                    checkOp: ['sys:configGet']
                }
            },
            {
                path: 'cloud',
                meta: {
                    menuIcon: 'mdi-cloud-outline',
                    menuText: '云服务连接',
                    checkOp: ['sys:configGet']
                }
            },
            {
                path: 'edgeMqtt',
                meta: {
                    menuIcon: 'mdi-alpha-m-box-outline',
                    menuText: 'Mqtt连接服务',
                    checkOp: ['sys:configGet']
                }
            },
            {
                path: 'edgeOvpn',
                meta: {
                    menuIcon: 'mdi-vpn',
                    menuText: 'OpenVPN服务端',
                    checkOp: ['sys:configGet']
                }
            },
            // {
            //     path: 'config',
            //     meta: {
            //         menuIcon: 'mdi-cog-outline',
            //         menuText: '系统配置',
            //         checkOp: ['sys:configGet']
            //     }
            // },
            // {
            //     path: 'roleAdmin',
            //     meta: {
            //         menuText: '角色配置',
            //         checkOp: ['account:setrole']
            //     }
            // },
            // {
            //     path: 'organizationAdmin',
            //     meta: {
            //         menuText: '组织机构',
            //         check: {
            //             loged,
            //             apiAuthAccount: 'organization/getSubs'
            //         }
            //     },
            //     // 与组织内的管理界面共用vue组件
            //     component: lazyLoad('organization/manage'),
            //     props: {
            //         isOrganizationAdmin: true
            //     },
            // },
        ]
    },
]

export { lazyLoad, routes }