<template>
    <v-card>
        <v-card-title>
            搜索添加联系人
        </v-card-title>
        <v-card-text>
            <!-- 搜索输入 -->
            <v-text-field v-model="searchInput" label="账号" append-icon="mdi-account-search" @click:append="search()" @keyup.enter="search()" />
            <!-- 结果列表 -->
            <v-list v-if="searchResult">
                <v-list-item v-for="item in searchResult" :key="item.id" @click="selectedId = item.id">
                    <v-list-item-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title>{{ item.username }}</v-list-item-title>
                        <v-list-item-subtitle>{{ item.id }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <!-- 账号确认 -->
            <roomEdit v-if="selectedId" :accountIdTarget="selectedId" @success="selectedId=null" />
        </v-card-text>
    </v-card>
</template>

<style scoped></style>
<script>

import { mapState } from 'pinia'
import { useSessionStore } from '@/store/session'
import roomEdit from './room1v1Edit.vue';

export default {
    components: { roomEdit },
    computed: mapState(useSessionStore, ['account', 'accountNotice']),
    data() {
        return {
            // 搜索输入
            searchInput: '',
            // 当前选中的用户id
            selectedId: null,
            // 查询到的结果集合
            searchResult: null
        }
    },
    methods: {
        // 搜索账号
        async search() {
            // 清空选择
            this.searchResult = null
            this.selectedId = null

            // 搜索
            const { error, data } = await axios.post('/api/account/search', { keyWord: this.searchInput })
            if (error) {
                return error.alert()
            }

            // 结果需要剔除自己
            this.searchResult = data.accounts.filter(i => i.id !== this.account.id)
        },
    }
}

</script>
  