module.exports = {
    show: { zh: '长度' },
    units: {
        m: {
            show: { zh: '米' },
        },
        cm: {
            show: { zh: '厘米' },
            a: 0.01,
        },
        mm: {
            show: { zh: '毫米' },
            a: 0.001,
        },
        km: {
            show: { zh: '千米' },
            a: 1000,
        },
        dm: {
            show: { zh: '分米' },
            a: 0.1,
        },
        um: {
            show: { zh: '微米' },
            a: 0.000001,
        },
    }
}