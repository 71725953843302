<template>
  <!-- 二维码渲染用的元素 -->
  <canvas ref='qrcanvas'/>
</template>
<style scoped>
</style>

<script>
const QRCode = require('qrcode')

export default {
  props: {
    // 请求二维码的API地址，该地址应能够反馈微信公众号临时二维码的反馈结构
    qrApiUrl: String,
  },
  data() {
    return {
      ticket: null,
      clock: null,
    }
  },
  // 加载时更新
  mounted() {
    this.renew()
  },
  // 销毁前清除更新闹钟
  beforeDestroy() {
    clearTimeout(this.clock)
  },
  methods: {
    // 更新二维码
    async renew() {
      const { error, data } = await axios.get(this.qrApiUrl)
      if (error) throw error

      // 设定定时器
      this.clock = setTimeout(() => this.renew(), data.expire_seconds * 1000)
      // 记录ticket
      this.ticket = data.ticket

      // 根据反馈的url信息，绘制二维码
      QRCode.toCanvas(this.$refs.qrcanvas,data.url,{
        width:215
      })
    },
  },
}
</script>
