module.exports = {
    show: { zh: '功率' },
    combx: {
        energy: 1,
        time: -1
    },
    units: {
        W: {
            show: { zh: '瓦' }
        },
        kW: {
            show: { zh: '千瓦' },
            a: 1000
        }
    }
}