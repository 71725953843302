<template>
    <v-card v-if="!accountTarget">
        <v-card-title>
            加载中……
        </v-card-title>
    </v-card>
    <v-card v-else-if="!room.joinTarget">
        <v-card-title>
            添加对方为好友
        </v-card-title>
        <v-card-text>
            <p><b>用户名：</b>{{ accountTarget.username }}</p>
        </v-card-text>
    </v-card>
    <v-card v-else-if="!room.joinSelf">
        <v-card-title>
            对方加你为好友
        </v-card-title>
        <v-card-text>
            <p><b>用户名：</b>{{ accountTarget.username }}</p>
            <v-radio-group v-model="editProps.authed" row>
                <v-radio label="通过" color="green" :value="true"></v-radio>
                <v-radio label="拒绝" color="red" :value="false"></v-radio>
            </v-radio-group>
        </v-card-text>
    </v-card>
    <v-card v-else>
        <v-card-title>
            好友信息
        </v-card-title>
        <v-card-text>
            <p><b>用户名：</b>{{ accountTarget.username }}</p>
            <v-text-field label="备注名 (仅自己可见)" v-model="editProps.nameremark" hide-details="true" />
            <v-radio-group v-model="editProps.authed" row>
                <v-radio label="接收对方消息" color="green" :value="true"></v-radio>
                <v-radio label="拒绝对方消息" color="red" :value="false"></v-radio>
            </v-radio-group>
            <v-btn @click="submit()">保存</v-btn>
        </v-card-text>
    </v-card>
</template>

<style scoped></style>
<script>

import axios from 'axios'
import { mapActions } from 'pinia'
import { useChatStore } from '../../store/chat'
import { getInfoAsync } from '../dom/accountInfo.vue'

export default {
    props: {
        // 对方的账号Id
        accountIdTarget: String,
    },
    // 属性变化时，重置
    watch: {
        accountIdTarget: {
            immediate: true,
            handler() {
                this.reset()
            }
        }
    },
    data() {
        return {
            // 对方账号信息
            accountTarget: null,
            // 房间信息
            room: null,
            // 对方控制属性
            editProps: {
                authed: null,
                nameremark: null,
            },
        }
    },
    methods: {
        ...mapActions(useChatStore, ['roomLoadByAccountIdTarget']),
        // 重置信息
        async reset() {
            // 复位
            this.accountTarget = null
            this.room = null
            Object.keys(this.editProps).forEach(k => this.editProps[k] = null)

            // 获取对方用户信息，当前与该用户的1v1房间信息
            const [accountTarget, room] = await Promise.all([
                getInfoAsync(this.accountIdTarget),
                this.roomLoadByAccountIdTarget(this.accountIdTarget)
            ])

            // 写入控制属性
            this.editProps.authed = room.joinTarget?.authed || false
            this.editProps.nameremark = room.joinTarget?.nameremark || ''

            // 必须最后写入对方账号信息
            this.room = room
            this.accountTarget = accountTarget
        },
        // 提交修改
        async submit() {
            // 调用API提交修改
            const { error } = await axios.post('/api/chat/roomConfig1v1', this.editProps, {
                params: {
                    targetAccount: this.accountIdTarget
                }
            })
            if (error) return error.alert()
            // 触发组件事件
            this.$emit('success', this.editProps)
        }
    }
}

</script>
  