import { defineStore } from 'pinia'

export const useSessionStore = defineStore('session', {
  state: () => ({
    /** 系统配置，直接从window对象获取 */
    sysInfo: window.sysInfo,
    /** 窗体状态 */
    // 窗体大小（height、width）
    winSize: null,
    // 是否是大屏模式，由appIndex中的showDrawer变化触发该项变更
    isBigScreen: null,
    // 窗口是否可见
    winVisible: false,

    /** 会话 */
    // 当前登录的账号，null表示未初始化，对象中无id，表示未登录
    account: null,
    // 角色标识集
    roles: null,
    // 许用操作符
    operations: null,
    /** 账号最新通知消息 */
    accountNotice: null,
  }),
  actions: {
    // state通用更新，建议在内部使用
    update(dict) {
      this.$patch(dict)
    },
    // appIndex中的showDrawer变化
    renewIsBigScreen(isShow) {
      this.isBigScreen = isShow
    },
    renewSessionInfo(json) {
      this.$patch(state=>{
        state.account = json.account || {}
        state.operations = json.operations
        state.roles = json.roles
      })
    }
  },
})
