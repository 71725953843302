module.exports = {
    show: { zh: '力' },
    combx: {
        mass: 1,
        length: 1,
        time: -2
    },
    units: {
        N: {
            show: { zh: '牛' },
        },
        kgf: {
            show: { zh: '千克力' },
            a: 9.80665
        }
    }
}