<template>
  <!-- 单项 -->
  <v-list-item v-if="!item.subs" :to="item.pathFull">
    <v-list-item-title>
      <v-icon v-if="item.meta.menuIcon">{{  item.meta.menuIcon  }}</v-icon>
      {{ item.meta.menuText }}
    </v-list-item-title>
  </v-list-item>
  <!-- 下级菜单 -->
  <v-list-group v-else>
    <template v-slot:activator>
      <v-list-item-title>
      <v-icon v-if="item.meta.menuIcon">{{  item.meta.menuIcon  }}</v-icon>
        {{ item.meta.menuText }}
      </v-list-item-title>
    </template>
    <!-- 遍历子菜单 -->
    <NavMenuItem style="padding-left: 2ch;" v-for="(it, index) in item.subs" v-bind:key="index" :item="it" :lv="lv  +  1" />
  </v-list-group>
</template>

<script>
export default {
  name: 'NavMenuItem',
  props: {
    // 菜单项目
    item: Object,
    // 当前菜单深度
    lv: Number
  },
};
</script>