module.exports = {
    show: { zh: '速度' },
    combx: {
        length: 1,
        time: -1
    },
    units: {
        'm/s': {
            show: { zh: '米每秒' }
        },
        'km/h': {
            show: { zh: '千米每小时' },
            combx: {
                length_km: 1,
                time_h: -1
            }
        },
        'mm/s': {
            show: { zh: '毫米每秒' },
            combx: {
                length_mm: 1,
                time_s: -1
            }
        },
        'mm/h': {
            show: { zh: '毫米每小时' },
            combx: {
                length_mm: 1,
                time_h: -1
            }
        },
    },
}