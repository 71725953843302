module.exports = {
    show: { zh: '频率' },
    combx: {
        time: -1
    },
    units: {
        Hz: {
            show: { zh: '赫兹' }
        },
        kHz: {
            show: { zh: '千赫兹' },
            a: 1e3
        },
        MHz: {
            show: { zh: '兆赫兹' },
            a: 1e6
        },
    }
}