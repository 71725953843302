/** store实例构建及输出 */
import { useSessionStore } from './session'
import { useChatStore } from './chat'
import { useSysStore } from './sys'

const store = {}
export default store
export function storeSetup(pinia) {
  store.session = useSessionStore(pinia)
  store.chat = useChatStore(pinia)
  store.sys = useSysStore(pinia)
}

/** ============== 事件交互体系 ============== */
/** Windows窗体变动 */
// 尺寸调整
function onWindowsResize() {
  store.session.update({
    winSize: {
      width: window.innerWidth,
      height: window.innerHeight,
    }
  })
}
setTimeout(onWindowsResize, 0)
window.onresize = onWindowsResize
// 页面是否隐藏
document.addEventListener("visibilitychange", function () {
  // 触发store内部事件
  store.session.update({
    winVisible: document.visibilityState === 'visible'
  })
  // 通知到sharedworker
  sconnSendVisibility()
});

/** 跨浏览器标签页通讯 */
// 构建浏览器跨tab页公用的worker
const sconn = new SharedWorker('static/sconn.js', 'sconn');
// 发送是否可见
function sconnSendVisibility() {
  sconn.port.postMessage('visibility_' + document.visibilityState)
}
setTimeout(sconnSendVisibility, 0)
// Window对象事件
window.onunload = () => {
  sconn.port.postMessage('window_onunload')
}
// 收到消息的处理
sconn.port.onmessage = (evt) => {
  const { cmd, json } = evt.data
  // 登录信息变更
  if (cmd === 'sessionInfo') {
    // 在session中登记
    store.session.renewSessionInfo(json)
    // 重建chat聊天室列表
    store.chat.rebuildRoomDict(json)
  }
  // 账号通知
  else if (cmd === 'accountNotice') {
    store.session.update({ accountNotice: json })
  }
  // 系统通知
  else if (cmd === 'sysNotice') {
    store.sys.update({
      [json.topic]: json.payload
    })
  }
}