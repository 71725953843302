
import Utils from './utils'

// 通用订阅类
class c_wsBook {
    // 完整的连接路径
    _url = null
    // ws实例
    _ws = null
    // 当前订阅的字典，key为keyMd5，value为{funcSet,keyObj,dataKeep}
    _map = new Map()
    // ws重连定时器，及延迟毫秒数
    _wsClock = null
    _wsDelayMs = 0

    /** 构造方法
     * 
     * @param {*} param0 
     */
    constructor(path) {
        // 构造连接地址，记录
        this._url = Utils.baseWsURL(path)
    }
    // key构造方法
    _keyMaker(keyObj) {
        return Object.keys(keyObj).sort().map(key => keyObj[key]).join('\t')
    }

    // 连接保持方法
    _wsKeep() {
        // 有实例，或者有重连定时器，不用继续
        if (this._ws || this._wsClock) return

        // 如果订阅字典为空，不用继续
        if (this._map.size === 0) return

        /*** 建立连接，订阅事件 ***/
        const ws = this._ws = new WebSocket(this._url)
        // 连接成功，发送当前所有订阅
        ws.onopen = () => {
            // 此ws非this._ws，关闭
            if (ws !== this._ws) {
                ws.close()
                return
            }
            // 定时器复位
            clearTimeout(this._wsClock)
            this._wsClock = null
            this._wsDelayMs = 0

            // 整理所有订阅集合
            const keysAll = Array.from(this._map.keys())
            // 若长度为0，关闭连接
            if (keysAll.length === 0) {
                ws.close()
            }
            // 有内容，逐项发送订阅请求
            else {
                for (const key of keysAll) {
                    this._wsSendSubscribe(key)
                }
            }
        }
        // 收到消息
        ws.onmessage = evt => {
            const json = JSON.parse(evt.data)
            // 收到推送值
            if (json.cmd === 'emit') {
                this._wsEmit(json)
            }
        }
        // 连接被关闭
        ws.onclose = code => {
            this._ws = null
            // 有订阅内容，异常断开，需重连
            if (this._map.size > 0) {
                if (!this._wsClock) {
                    // 设定定时器
                    const clock = this._wsClock = setTimeout(() => {
                        // 定时器无效，不继续
                        if (clock !== this._wsClock) return
                        // 清空定时器标记
                        this._wsClock = null
                        // 计算重连延迟，每次尝试增加1秒间隔，最多10秒
                        this._wsDelayMs = Math.min(this._wsDelayMs + 1000, 10000)
                        // 调用连接维护方法
                        this._wsKeep()
                    }, this._wsDelayMs)
                }
            }
        }
    }

    // ws发送订阅消息的指令
    _wsSendSubscribe(key) {
        // 有ws实例，且状态可用
        if (this._ws && this._ws.readyState === 1) {
            this._ws.send(JSON.stringify({
                cmd: 'subscribe',
                key
            }))
        }
        // 没有，调用一次连接维护
        else {
            this._wsKeep()
        }
    }

    // 后台推送消息
    _wsEmit(json) {
        // 预备取消订阅的方法，不一定调用
        const ws = this._ws
        const unSubscribe = () => {
            if (ws) ws.send(JSON.stringify({
                cmd: 'unsubscribe',
                key: json.key
            }))
        }

        // 取订阅对象，取不到直接告知后台取消订阅
        const subObj = this._map.get(json.key)
        if (!subObj) return unSubscribe()

        // 更新的属性项目集合
        subObj.propsChange = Object.keys(json.props)
        // 如果dataKeep为空，完整记录
        if (!subObj.dataKeep) {
            subObj.dataKeep = json.props
        }
        // 非空，增量更新
        else {
            for (const prop of subObj.propsChange) {
                subObj.dataKeep[prop] = json.props[prop]
            }
        }

        // 遍历订阅的funcs
        for (const func of subObj.funcSet.values()) {
            // 调用，返回值为true表示取消订阅，从funcSet移除
            if (this._funcCall(func, subObj)) {
                subObj.funcSet.delete(func)
            }
        }
        // 如果订阅量为0，从map删除该obj，并发送取消订阅消息
        if (subObj.funcSet.size === 0) {
            this._map.delete(json.key)
            unSubscribe()
            // 如果删除后map订阅数量为0，尝试关闭ws
            if (this._map.size === 0 && this._ws) {
                this._ws.close()
            }
        }
    }

    // 回调函数执行方法
    _funcCall(func, subObj) {
        return func(
            null,
            subObj.dataKeep,
            {
                keyObj: subObj.keyObj,
                propsChange: subObj.propsChange
            }
        )
    }

    // 订阅方法
    subscribe(keyObj, func) {
        // 取订阅方法集合
        const key = this._keyMaker(keyObj)
        let subObj = this._map.get(key)

        // 若没有，新建
        if (!subObj) {
            // 预备结构，并将结构记录到_map
            subObj = {
                keyObj,
                funcSet: new Set(),
                dataKeep: null,
                propsChange: null
            }
            this._map.set(key, subObj)
            // 发送订阅消息
            this._wsSendSubscribe(key)
        }

        // 添加func
        subObj.funcSet.add(func)

        // 如果有缓存dataKeep，异步调用一次
        if (subObj.dataKeep) {
            setTimeout(() => this._funcCall(func, subObj), 0)
        }
    }
}

export default c_wsBook