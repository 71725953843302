module.exports = {
    show: { zh: '电压' },
    combx: {
        power: 1,
        current: -1
    },
    units: {
        V: {
            show: { zh: '伏' }
        },
        mV: {
            show: { zh: '毫伏' },
            a: 0.001
        },
        kV: {
            show: { zh: '千伏' },
            a: 1000
        }
    }
}