module.exports = {
    show: {
        zh: '体积',
    },
    combx: {
        length: 3
    },
    units: {
        m3: {
            sign: 'm\u00b3',
            show: { zh: '立方米' }
        },
        L: {
            show: { zh: '升' },
            combx: {
                length_dm: 3
            }
        },
        mL: {
            show: { zh: '毫升' },
            combx: {
                length_cm: 3
            }
        }
    }
}