module.exports = {
    show: { zh: '转速/角速度' },
    combx: {
        degree: 1,
        time: -1
    },
    units: {
        'rad/s': {
            show: { zh: '弧度每秒' }
        },
        'RPM': {
            show: { zh: '转每分钟' },
            combx: {
                degree_turn: 1,
                time_m: -1
            }
        }
    }
}