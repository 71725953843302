module.exports = {
    show: { zh: '温度' },
    units: {
        C: {
            sign: '℃',
            show: { zh: '摄氏度' },
            a: 1,
            b: 273.15
        },
        F: {
            sign: '℉',
            show: { zh: '华氏度' },
            a: 5 / 9,
            b: 2298.35 / 9
        },
        K: {
            show: { zh: '开尔文' },
        },
    }
}