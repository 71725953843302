<template>
    <div>
        <v-card v-if="room && joinDict" style="height:100%; display: flex;flex-direction: column">
            <template v-if="room && joinDict && room.roomType === '1v1'">
                <v-btn text @click="dialog_roomEdit = true">
                    <v-icon>mdi-account</v-icon>
                    <account-info :accountId="room.joinTarget.accountId" :nameremark="room.joinTarget?.nameremark || ''" />
                    <!-- 双方好友添加告知 -->
                    <span v-if="!room.joinSelf.authed" style="color: darkorange;"> # 对方未确认接收消息</span>
                    <span v-if="!room.joinTarget.authed" style="color: darkred;"> # 您未确认接收对方消息，请点击授权</span>

                    <!-- 编辑弹框，默认隐藏 -->
                    <v-dialog v-model="dialog_roomEdit" width="500">
                        <room1v1-edit v-if="dialog_roomEdit" :accountIdTarget="room.joinTarget.accountId"
                            @success="dialog_roomEdit = false" />
                    </v-dialog>
                </v-btn>
            </template>
            <!-- 消息阅读区 -->
            <v-card-text v-if="!joinDict">加载中……</v-card-text>
            <v-card-text v-else ref="card-text" class="card-text"
                :style="[{ 'max-height': (winSize.height - 298) + 'px' }]">
                <msg-item v-for="(msg, index) in msgs" :key="index" :join="joinDict[msg.joinId]" :m-this="msg"
                    :m-last="msgs[index - 1] || null" />
            </v-card-text>
            <!-- 消息发送区 -->
            <msg-editor :msgSendCode="msgSendCode" @submit="sendMsg($event)" />
        </v-card>
    </div>
</template>

<style scoped>
.card-text {
    overflow-y: auto;
    flex: 1;
}

.msg-head-self {
    color: rgb(1, 83, 19);
}

.msg-head-oppo {
    color: rgb(1, 1, 83);
}
</style>

<script>
import { mapState } from 'pinia'
import { useSessionStore } from '../store/session'
import { useChatStore } from '../store/chat'
import room1v1Edit from '../components/chat/room1v1Edit.vue'
import accountInfo from '../components/dom/accountInfo.vue'
import MsgItem from '../components/chat/msgItem.vue'
import MsgEditor from '../components/chat/msgEditor.vue'

export default {
    components: { accountInfo, MsgItem, MsgEditor, room1v1Edit },
    computed: {
        ...mapState(useSessionStore, ['account', 'accountNotice', 'winSize', 'winVisible']),
        ...mapState(useChatStore, ['roomDict'])
    },
    // 不考虑属性变化
    props: {
        roomId: Number,
    },
    data() {
        return {
            // 聊天室信息，加入字典，对方加入信息
            room: null,
            joinDict: null,
            // 编辑弹框
            dialog_roomEdit: false,
            // 当前输入框的文字
            msgInput: '',
            // 发送状态：0 - 编辑，1 - 发送中，2 - 发送失败
            msgSendCode: 0,
            // 聊天记录集合
            msgs: [],
            // 滚动到底部的定时器
            scrollClock: null
        }
    },
    mounted() {
        // 更新聊天室信息
        this.renew_room()
        // 更新消息
        this.msgRenewForward()
    },
    watch: {
        // 监听ws事件，是否与该会话有关
        accountNotice(event) {
            // 不处理的情况
            if (
                event.topic !== 'chat' || // 非聊天通知
                event.payload.roomId !== this.room?.roomId // 房间id不匹配
            ) return

            // 插入消息
            this.msgInsert(event.payload.msg)
        },
        // 当窗口可见时
    },
    methods: {
        // 更新聊天室信息，含成员列表
        renew_room() {
            // 先复位
            this.joinDict = null
            this.room = null

            // 检出room
            const room = this.roomDict[this.roomId]

            // 整理joinDict
            const joinDict = {}
            room.joins.forEach(j => joinDict[j.id] = j)

            // 记录到this
            this.joinDict = joinDict
            this.room = room
        },
        // 消息更新向前
        async msgRenewForward() {
            // 检索条件
            let ts, isForward
            // 当前消息数量
            const len = this.msgs.length
            // 初次更新
            if (len === 0) {
                // 起始时间戳给一个很大的值
                ts = 9e12
                // 向回查找
                isForward = false
            }
            // 非初次
            else {
                // 取最后一条作为起点时间戳
                ts = this.msgs[len - 1].ts
                // 向前查找
                isForward = true
            }

            // 开始检索
            const { error, data } = await axios.get('/api/chat/msg', {
                params: {
                    roomId: this.room.roomId,
                    ts,
                    isForward,
                }
            })
            if (error) return error.alert()

            // 如果没有结束，且非首次请求，继续
            if (!data.isEnd && len > 0) {
                setTimeout(() => {
                    this.msgRenewForward()
                }, 0)
            }

            // 将消息合并到msgs数组
            data.msgs.forEach(msg => this.msgInsert(msg))
        },
        // 发送消息
        async sendMsg(content) {
            // 标记正在发送
            this.msgSendCode = 1

            // 调用接口
            const { error } = await axios.post('/api/chat/msg', {
                roomId: this.room.roomId,
                content,
            })
            // 失败
            if (error) {
                error.alert()
                this.msgSendCode = 2
            }
            // 成功
            else {
                this.msgSendCode = 0
            }
        },
        // 插入新消息
        msgInsert(msg) {
            // 最后一条消息，可能为undefined
            const msgLast = this.msgs[this.msgs.length - 1]
            // 没有最后一条，直接放在最后
            if (!msgLast) {
                this.msgs.push(msg)
            }
            // 时间戳比最后一条还靠后，放在最后面
            else if (msg.ts > msgLast.ts) {
                this.msgs.push(msg)
            }
            // 定位应该放在哪，重复消息
            else {
                // 定位，-1 表示不需要插入
                let i
                for (i = 0; i < this.msgs.length; i++) {
                    const m = this.msgs[i]
                    // 已有重复消息
                    if (m.ts === msg.ts && m.joinId === msg.joinId) {
                        i = -1
                        break
                    }
                    // 遍历到应插入的位置
                    if (m.ts > msg.ts) {
                        break
                    }
                }
                // 插入
                if (i >= 0) {
                    this.msgs.splice(i, 0, msg)
                }
            }
            // 滚动条到底部
            clearTimeout(this.scrollClock)
            this.scrollClock = setTimeout(() => {
                const readDiv = this.$refs['card-text']
                readDiv.scrollTop = readDiv.scrollHeight + 1000
            }, 50)
        },
        // 聊天室信息更新
        roomEditUpdate() {
            // 关闭信息弹窗
            this.dialog_roomEdit = false
            // 刷新信息
            this.renew_room()
        },
        // 获取加入信息
        getJoinByAccountId(accountId) {
            return this.room.joins.filter(i => i.accountId === accountId)[0] || null
        }
    },
}
</script>
  