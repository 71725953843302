module.exports = {
    show: { zh: '电流' },
    units: {
        A: {
            show: { zh: '安培' }
        },
        mA: {
            show: { zh: '毫安' },
            a: 0.001
        }
    }
}