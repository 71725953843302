import { defineStore } from 'pinia'

export const useSysStore = defineStore('sys', {
    state: () => ({
        // 后台控制信息
        ctrlInfo: null
    }),
    actions: {
        update(dict) {
            this.$patch(dict)
        },
    }
})
