import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

import { routes, lazyLoad } from './routes'


Vue.use(VueRouter)

// 路由项目迭代整理
function routeFmt(item, path = '') {
  // 对item.path整理，去除参数部分
  const ind = item.path.indexOf('/:')
  if (ind >= 0) item.path = item.path.substr(0, ind)
  // 完整路径
  const pathFull = path ? path + '/' + item.path : item.path
  // 1 - 如果有children，迭代
  if (item.children) item.children.forEach(i => routeFmt(i, pathFull))
  // 2 - 根据需要自动添加懒加载：无child、未设定跳转，且未直接给定component
  if (!item.children && !item.redirect && !item.component) item.component = lazyLoad('views' + pathFull)
  // 3 - 若meta未定义，给空对象
  if (!item.meta) item.meta = {}
  // 4 - 若checkOp未定义，给空数组
  if (!item.meta.checkOp) item.meta.checkOp = []
  // 5 - 记录完整路径
  item.pathFull = pathFull
}

routes.forEach(i => routeFmt(i))

const router = new VueRouter({ routes })

// 路由权限检查方法
function checkIsPass(route) {
  // 检查配置项
  const checkOp = route.meta.checkOp
  // 没有检查项，或为空数组，直接反馈通过
  if (!checkOp || checkOp.length === 0) return true

  // 取当前session允许的操作代码集合，获取不到时给空数组
  const operations = store.session.operations || []

  // 遍历各项检查，若有一项满足，则予以通过，即checkOp为或的关系
  for (const op of checkOp) {
    if (operations.includes(op)) return true
  }

  // 未通过，反馈false
  return false
}

// 路由跳转权限检查
router.beforeEach(async (to, from, next) => {
  // 如果尚未获取到登录状态，延迟等待
  while (true) {
    if (store.session.account) break
    await new Promise(r => setTimeout(r, 20))
  }
  // 检查权限
  if (checkIsPass(to)) next()
  // 验证失败
  else {
    if (store.session.account.id) alert('未经授权的访问')
    else alert('未登录或登录失效')
    next(from)
  }
})

router.checkIsPass = checkIsPass

export default router
