import axios from 'axios'
import Utils from './utils'
// http层错误代码不抛出异常
axios.defaults.validateStatus = () => { return true }
// 请求拦截器
axios.interceptors.request.use(config => {
  /** 如果是调用平台后端接口的请求，拦截处理 */
  if (!config.baseURL && config.url.substr(0, 1) === '/') {
    // 外部传入sessionId时，附加到url后面
    const query = Utils.strVars2dict(window.location.search)
    if (query.sessionId) {
      config.params = config.params || {}
      config.params.sessionId = query.sessionId
    }
    /* 设定基础路径 */
    config.baseURL = Utils.baseURL('')
    // 跨域请求时，允许携带cookie
    config.withCredentials = !!window.cnameInfoJson
  }

  return config;
})
// 响应拦截器
axios.interceptors.response.use(res => {
  // 如果有错误，构造error对象
  if (res.status < 200 || res.status >= 300) {
    res.error = new c_apiError(res)
  }

  return res
})

// api错误对象
class c_apiError {
  constructor(res) {
    this.status = res.status
    const { code, message, details } = res.data || {}
    this.code = code
    this.message = message
    this.details = details
  }
  alert() {
    alert(`[API调用错误]
    \nStatus : ${this.status}
    \nCode   : ${this.code}
    \nMessage: ${this.message}
    \nDetails: ${this.details}
    `)
  }
}

// 注入到window对象
export default axios