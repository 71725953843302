module.exports = {
    show: { zh: '角度' },
    units: {
        rad: {
            show: { zh: '弧度' },
        },
        deg: {
            show: { zh: '角度' },
            a: Math.PI / 180
        },
        turn: {
            show: { zh: '转' },
            a: Math.PI * 2
        },
    }
}