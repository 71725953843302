module.exports = {
    show: { zh: '时间' },
    units: {
        s: {
            show: { zh: '秒' },
        },
        m: {
            show: { zh: '分钟' },
            a: 60,
        },
        h: {
            show: { zh: '时' },
            a: 3600,
        },
        d: {
            show: { zh: '天' },
            a: 86400,
        },
        ms: {
            show: { zh: '毫秒' },
            a: 0.001,
        }
    }
}