<template>
    <div>
        <v-tabs v-model="chatTab" background-color="info" grow>
            <v-tab key="1v1" style="min-width: 70px;">
                <v-icon>mdi-account</v-icon>
            </v-tab>
            <v-tab key="group" style="min-width: 70px;">
                <v-icon>mdi-account-group-outline</v-icon>
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="chatTab">
            <v-tab-item key="1v1">
                <v-btn v-if="account?.operations?.includes('account:search')" class="mx-2" dark color="primary" @click="dialog_addUser = true">
                    <v-icon dark>
                        mdi-account-plus-outline
                    </v-icon>
                    添加联系人
                    <v-dialog v-model="dialog_addUser" width="500">
                        <add-user-search v-if="dialog_addUser"></add-user-search>
                    </v-dialog>
                </v-btn>
                <v-list dense>
                    <v-list-item v-for="room in roomDict" :key="room.roomId" v-if="room.roomType==='1v1'" @click="$emit('select', room)">
                        <v-list-item-icon>
                            <v-icon>{{ room.roomIcon || 'mdi-account' }}</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>
                                <account-info :accountId="room.joinTarget.accountId" :nameremark="room.joinTarget?.nameremark||''" />
                            </v-list-item-title>
                            <!-- <v-list-item-subtitle>{{ account.username }}</v-list-item-subtitle> -->
                        </v-list-item-content>

                        <!-- <v-list-item-avatar>
                            <v-img :src="item.avatar"></v-img>
                        </v-list-item-avatar> -->
                    </v-list-item>
                </v-list>
            </v-tab-item>
            <v-tab-item key="group">
                <v-btn class="mx-2" dark color="primary">
                    <v-icon dark>
                        mdi-account-multiple-plus-outline
                    </v-icon>
                    加入群聊
                </v-btn>
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>
  
<script>
import { mapState } from 'pinia'
import { useSessionStore } from '@/store/session'
import { useChatStore } from '../store/chat'
import accountInfo from '../components/dom/accountInfo.vue'
import addUserSearch from '../components/chat/addUserSearch.vue'

export default {
    name: 'ChatCtrl',
    computed: {
        ...mapState(useSessionStore, ['account']),
        ...mapState(useChatStore, ['roomDict']),
    },
    components: { accountInfo, addUserSearch },
    data() {
        return {
            chatTab: '1v1',
            dialog_addUser: false,
        }
    },
    methods: {
        // 选定room
        async selectRoom(room) {
            this.$emit('select', room)
        }
    }
};
</script>