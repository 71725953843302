module.exports = {
    show: { zh: '体积流量' },
    combx: {
        volume: 1,
        time: -1
    },
    units: {
        'm3/s': {
            sign: 'm\u00b3/s',
            show: { zh: '立方米每秒' }
        },
        'm3/min': {
            sign: 'm\u00b3/min',
            show: { zh: '立方米每分钟' },
            combx: {
                time_m: -1
            },
        },
        'm3/h': {
            sign: 'm\u00b3/h',
            show: { zh: '立方米每小时' },
            combx: {
                time_h: -1
            },
        },
        'm3/d': {
            sign: 'm\u00b3/d',
            show: { zh: '立方米每天' },
            combx: {
                time_d: -1
            },
        },
    }
}