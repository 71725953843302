<template>
    <div style="clear: both;">
        <p v-if="dateTimeStr" :class="join.accountId === account.id ? 'msg-head-self' : 'msg-head-oppo'">
            <span v-if="join.nameremark">{{ join.nameremark }}</span>
            <account-info v-else :accountId="join.accountId" />
            {{ dateTimeStr }}
        </p>
        <div>
            <div class="pointer">●</div>
            <div v-html="contentFormat(mThis.content, mThis.meta)" class="passover"></div>
        </div>
    </div>
</template>

<style scoped>
.pointer {
    font-size: large;
    float: left;
    width: 1em
}

.passover :deep(p) {
    font-size: large;
    margin: 0.2em auto;
}

.passover :deep(img) {
    max-width: 48em;
    max-height: 27em;
}

.passover {
    float: left;
    max-width: 70%
}

.msg-head-self {
    font-weight: bold;
    margin: 0 auto;
    color: rgb(1, 137, 30);
}

.msg-head-oppo {
    font-weight: bold;
    margin: 0 auto;
    color: rgb(2, 2, 153);
}
</style>
<script>

import { mapState } from 'pinia'
import { useSessionStore } from '@/store/session'
import accountInfo from '../dom/accountInfo.vue'

export default {
    components: { accountInfo },
    computed: mapState(useSessionStore, ['account', 'accountNotice']),
    props: {
        // 本条消息
        mThis: Object,
        // 上一条消息
        mLast: Object,
        // 发送方加入信息
        join: Object,
    },
    data() {
        return { dateTimeStr: '' }
    },
    mounted() {
        // 缩写
        const t = this.mThis
        const l = this.mLast || {}

        /** 组织时间字符串 */
        const dateThis = this.$moment(t.ts).format('YYYY-MM-DD')
        const dateLast = this.$moment(l.ts || 0).format('YYYY-MM-DD')
        const timeThis = this.$moment(t.ts).format('HH:mm:ss')
        let dateTimeStr
        // 日期变更(含首条tsLast为0)
        if (dateThis !== dateLast) dateTimeStr = timeThis + ` (${dateThis})`
        // 间隔超过5分钟或发送人变化
        else if (t.ts - l.ts > 2 * 60000 || t.joinId !== l.joinId) dateTimeStr = timeThis

        // 记录到this
        this.dateTimeStr = dateTimeStr
    },
    methods: {
        // 消息体格式化
        contentFormat(content, meta) {
            content = fmtMsgImages(content)
            return content
        }
    }
}

// msg中images的提取及整理
function fmtMsgImages(content) {
    // 提取img
    const regex = /<img s3v1="(?<bucket>.*?):(?<key>.*?)" (?<aft>.*?)\/>/g

    // 使用matchAll方法，对目标字符串进行全局匹配，并返回一个迭代器对象
    const matches = content.matchAll(regex)

    // 遍历匹配
    for (const match of matches) {
        const [full, bucket, key, aft] = match
        // 替换content，务必在上传之前，之后会修改input.Bucket内容，补充前缀
        content = content.replace(full, `<img src="api/s3get?p=${bucket}/${key}" ${aft}/>`)
    }

    // 反馈整理后的内容
    return content
}
</script>
  