<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img :src="require('../assets/logo-no-background.svg')" class="my-3" contain height="200" />
      </v-col>

      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">
          Welcome to EPlant
        </h1>

        <p class="subheading font-weight-regular">
          简洁、专业的数据连接平台
        </p>
        <p class="subheading font-weight-regular">
          了解更多：<a href="https://www.esimu.cn" target="_blank">https://www.esimu.cn</a>
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'HelloWorld',

  data: () => { return {} },
}
</script>
