module.exports = {
    show: { zh: '面积' },
    combx: {
        length: 2
    },
    units: {
        m2: {
            sign: 'm\u00b2',
            show: { zh: '平方米' }
        },
        cm2: {
            sign: 'cm\u00b2',
            show: { zh: '平方厘米' },
            combx: {
                length_cm: 2
            }
        },
        km2: {
            sign: 'km\u00b2',
            show: { zh: '平方千米' },
            combx: {
                length_km: 2
            }
        },
        mu: {
            show: { zh: '亩' },
            a: 2000 / 3,
        },
        hm2: {
            sign: 'hm\u00b2',
            show: { zh: '公顷' },
            a: 10000,
        }
    }
}