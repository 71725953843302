/** 日期格式化函数
 * 
 * @param {*} dateIn 
 * @param {*} format 
 */
function dateFmt(dateIn, format = 'YYYY-MM-dd hh:mm:ss') {
    // 没有输入，反馈特定字符串
    if (!dateIn) return '--'
    // 支持时间戳
    if (typeof dateIn === 'number') dateIn = new Date(dateIn)

    var date = {
        "M+": dateIn.getMonth() + 1,
        "d+": dateIn.getDate(),
        "h+": dateIn.getHours(),
        "m+": dateIn.getMinutes(),
        "s+": dateIn.getSeconds(),
        "q+": Math.floor((dateIn.getMonth() + 3) / 3),
        "S+": dateIn.getMilliseconds()
    };
    if (/(y+)/i.test(format)) {
        format = format.replace(RegExp.$1, (dateIn.getFullYear() + '').substr(4 - RegExp.$1.length));
    }
    for (var k in date) {
        if (new RegExp("(" + k + ")").test(format)) {
            format = format.replace(RegExp.$1, RegExp.$1.length == 1 ?
                date[k] : ("00" + date[k]).substr(("" + date[k]).length));
        }
    }
    return format;
}

function strVars2dict(strVars = '') {
    const dict = {}
    strVars.substr(1).split('&').forEach(item => {
        const [k, v] = item.split('=')
        if (k) dict[k] = v
    })
    return dict
}

/** 后台基础url构造 */
function baseURL(path) {
    // 有cname配置信息
    if (window.cnameInfoJson) {
        return window.cnameInfoJson.frontBaseUrl + path
    }
    // 没有，从当前浏览器地址取
    else {
        return window.location.protocol + '//' + window.location.host + path
    }
}

/** 后台ws连接构造 */
function baseWsURL(path) {
    return 'ws' + baseURL(path).substr(4)
}

/** 随机uuid生成方法，复制于：https://www.cnblogs.com/yeminglong/p/6249077.html */
function uuidGen() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

const DasInfoMap = require('../../../libs-nodejs/dasInfoMap')

export default {
    dateFmt,
    strVars2dict,
    baseURL,
    baseWsURL,
    uuidGen,
    dict_edgeType: DasInfoMap.dict_edgeType,
    dict_protocol: DasInfoMap.dict_protocol,
    dict_structure: DasInfoMap.dict_structure,
    getProtocolObject: DasInfoMap.getProtocolObject,
    getEdgeTypeObject: DasInfoMap.getEdgeTypeObject,
    getStructureObject: DasInfoMap.getStructureObject,
}