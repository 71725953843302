module.exports = {
    show: { zh: '能量' },
    combx: {
        force: 1,
        length: 1
    },
    units: {
        J: {
            combx: {
                force_N: 1,
                length_m: 1
            },
            show: { zh: '焦耳' }
        },
        'kWh': {
            show: { zh: '千瓦时' },
            combx: {
                power_kW: 1,
                time_h: 1
            }
        }
    },
}