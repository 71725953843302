module.exports = {
    show: { zh: '质量流量' },
    combx: {
        mass: 1,
        time: -1
    },
    units: {
        'kg/s': {
            show: { zh: '千克每秒' }
        },
        'kg/h': {
            show: { zh: '千克每小时' },
            combx: {
                time_h: -1
            }
        },
        't/h': {
            show: { zh: '吨每小时' },
            combx: {
                mass_t: 1,
                time_h: -1
            }
        },
        't/d': {
            show: { zh: '吨每天' },
            combx: {
                mass_t: 1,
                time_d: -1
            }
        },
    }
}