<!-- 编辑器参考：https://www.wangeditor.com/ -->
<template>
    <div style="border: 1px solid #ccc;height: 180px">
        <div>
            <Toolbar style="float:left; border-bottom: 1px solid #ccc" :editor="editor" :defaultConfig="toolbarConfig"
                :mode="mode" />
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn style="float:left" color="primary" :disabled="msgSendCode === 1" @click="submit()" v-bind="attrs"
                        v-on="on">发送</v-btn>
                </template>
                <span>快捷键<br>Ctrl + Enter</span>
            </v-tooltip>
        </div>
        <div style="clear:both">
            <Editor style="overflow-y: hidden;" v-model="html" :defaultConfig="editorConfig" :mode="mode"
                @onCreated="onCreated" />
        </div>
    </div>
</template>

<style src="@wangeditor/editor/dist/css/style.css"></style>
<script>

import { Editor, Toolbar } from '@wangeditor/editor-for-vue'

export default {
    components: { Editor, Toolbar },
    props: {
        msgSendCode: Number
    },
    data() {
        return {
            editor: null,
            html: '',
            toolbarConfig: {
                toolbarKeys: [
                    'emotion',
                    'uploadImage',
                    "|",
                    "bold",
                    "underline",
                    "italic",
                    "through",
                    'color',
                ]
            },
            editorConfig: {
                placeholder: '请输入内容...',
                MENU_CONF: {
                    // 上传图片允许的最大大小
                    uploadImage: {
                        base64LimitSize: 2 * 1024 * 1024
                    }
                },
            },
            mode: 'simple', // or 'simple'
        }
    },
    created() {
        window.addEventListener('keydown', this.hotkey)
    },
    beforeDestroy() {
        window.removeEventListener('keydown', this.hotkey)
        const editor = this.editor
        if (editor == null) return
        editor.destroy() // 组件销毁时，及时销毁编辑器
    },
    watch: {
        // 发送状态变动
        msgSendCode(to) {
            switch (to) {
                // 发送成功，回归到编辑状态
                case 0:
                    this.editor.clear()
                    this.editor.enable()
                    break
                // 发送中
                case 1:
                    this.editor.disable()
                    break
                // 上次发送失败
                case 2:
                    this.editor.enable()
                    break
            }
        }
    },
    methods: {
        // 编辑器创建
        onCreated(editor) {
            this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
            // console.log(editor.getAllMenuKeys())
        },
        // 键盘快捷键，ctrl + 系列
        hotkey(evt) {
            // 不处理的情况
            if (
                !evt.ctrlKey || // ctrl没有按下
                ['ControlLeft', 'ControlRight'].includes(evt.code) || // 按下的本就是ctrl
                !this.editor.isFocused() // 编辑器未聚焦
            ) return

            // Ctrl+Entry，发送
            if (evt.code === 'Enter') {
                this.submit()
            }
        },
        // 发送按钮
        submit() {
            // 如果内容为空，不发送
            if (this.editor.isEmpty()) return

            // 触发事件
            this.$emit('submit', this.html)
        }
    },
}
</script>
  