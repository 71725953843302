import axios from 'axios'
import { defineStore } from 'pinia'

export const useChatStore = defineStore('chat', {
    state: () => ({
        roomDict: {}
    }),
    actions: {
        // 重新加载房间列表
        async rebuildRoomDict(sessionInfo) {
            // 重置
            this.roomDict = {}

            // 若非登录状态至此结束
            if (!sessionInfo.operations.includes('account:logout')) return

            // 向后台请求roomIds
            const { error, data } = await axios.get('/api/chat/roomList')
            if (error) return error.alert()

            // 逐个房间初始化
            data.roomIds.forEach(roomId => this.roomLoadById(roomId))
        },
        // 房间初始化
        roomFmtReg(room) {
            // 生成自身对象
            room.joinSelf = room.joins[room.joinIndex]

            // 如果是1v1聊天，提取joinTarget
            if (room.roomType === '1v1') {
                room.joinTarget = room.joins[1 - room.joinIndex]
            }

            // 写入字典
            this.roomDict = {
                ...this.roomDict,
                [room.roomId]: room
            }
        },
        // 房间加载
        async roomLoadById(roomId) {
            // 如果未初始化，先初始化
            if (!this.roomDict[roomId]) {
                // 向后台请求roomInfo
                const { error, data } = await axios.get('/api/chat/room?roomId=' + roomId)
                if (error) return error.alert()

                // 格式化并注册
                this.roomFmtReg(data)
            }

            return this.roomDict[roomId]
        },
        async roomLoadByAccountIdTarget(accountIdTarget) {
            // 遍历字典寻找当前是否已存在
            let room
            for (room of Object.values(this.roomDict)) {
                if (room.joinTarget.accountId === accountIdTarget) break
            }

            // 不存在，调用后台接口读取
            if (!room) {
                // 向后台请求roomInfo
                const { error, data } = await axios.get('/api/chat/room', {
                    params: { accountIdTarget }
                })
                if (error) return error.alert()

                // 格式化并注册
                this.roomFmtReg(data)

                // 提取room
                room = this.roomDict[data.roomId]
            }

            return room
        }
    }
})
