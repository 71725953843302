module.exports = {
    show: {
        zh: '压强'
    },
    combx: {
        force: 1,
        area: -1
    },
    units: {
        Pa: {
            show: {
                zh: '帕'
            }
        },
        kPa: {
            show: {
                zh: '千帕'
            },
            a: 1000
        },
        MPa: {
            show: {
                zh: '兆帕'
            },
            a: 1000000
        },
        atm: {
            show: {
                zh: '大气压'
            },
            a: 101325
        },
        mmHg: {
            show: {
                zh: '毫米汞柱'
            },
            a: 133.3223684
        },
        bar: {
            show: {
                zh: '巴'
            },
            a: 100000
        },
        mbar: {
            show: {
                zh: '毫巴'
            },
            a: 100
        },
        'kgf/cm2': {
            show: {
                zh: '千克力每平方厘米'
            },
            sign: 'kgf/cm\u00b2',
            combx: {
                force_kgf: 1,
                area_cm2: -1
            }
        },
        'kgf/m2': {
            show: {
                zh: '千克力每平方米'
            },
            sign: 'kgf/m\u00b2',
            combx: {
                force_kgf: 1,
                area_m2: -1
            }
        },
    }
}