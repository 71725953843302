<template>
  <span>{{ text }}</span>
</template>

<script>

// 本批次读取的账号列表
let accountIdMap = null
// 内存缓存
const accountInfoCacheMap = new Map()
// 异步获取某一账号的信息
export function getInfoAsync(accountId) {
  // 如果有内存缓存，直接反馈
  const infoCache = accountInfoCacheMap.get(accountId)
  if (infoCache) return infoCache

  // 当前map为空，初始化map，延迟执行调用请求
  if (!accountIdMap) {
    // 创建map
    accountIdMap = new Map()
    // 延迟执行接口调用，期间该方法再次被调用时，map中的信息会累积
    setTimeout(async () => {
      // 延迟结束，确定此次请求的内容，map复位
      const map = accountIdMap
      accountIdMap = null
      // 整理账号ID数组
      const accountIds = Array.from(map.keys())
      // 异步调用API接口，并进行错误处理
      const { error, data } = await axios.post('/api/account/publicInfo', { accountIds })
      if (error) {
        // 遍历字典各项目，反馈undefined
        for (const array of map) {
          array.forEach((resolve) => resolve(undefined))
        }
      }
      // 请求成功
      else {
        // 遍历反馈的结果项目
        for (const info of data.list) {
          // 写入内存缓存
          accountInfoCacheMap.set(info.id, info)
          // 取出对应array执行其中的resolve方法
          map.get(info.id).forEach((resolve) => resolve(info))
          // 从字典删除
          map.delete(info.id)
        }
        // 遍历字典剩余项目，反馈null
        for (const array of map) {
          array.forEach((resolve) => resolve(null))
        }
      }
    }, 0)
  }

  // 构造Promise对象，反馈
  return new Promise((resolve) => {
    // 取出该accountId对应的array，没有则创建
    let array = accountIdMap.get(accountId)
    if (!array) {
      array = []
      accountIdMap.set(accountId, array)
    }
    // array中记录所有的resolve
    array.push(resolve)
  })
}

export default {
  props: {
    // 账号id
    accountId: String,
    // 备注名称
    nameremark: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      text: '',
    }
  },
  async created() {
    const info = await getInfoAsync(this.accountId)
    const username = (info && info.username) || ''

    if (this.nameremark) {
      this.text = `${this.nameremark} (${username})`
    }
    else {
      this.text = username
    }
  },
}
</script>
