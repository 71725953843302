import axios from './assets/axios'
import moment from 'moment'
import Vue from 'vue'

// vuetify
import Vuetify from 'vuetify/lib';
import '@mdi/font/css/materialdesignicons.css'


// element-ui & avue
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Avue from '@smallwei/avue';
import '@smallwei/avue/lib/index.css';

// 引入全局存储
import { createPinia, PiniaVuePlugin } from 'pinia'
Vue.use(PiniaVuePlugin)
const pinia = createPinia()
import { storeSetup } from './store'
storeSetup(pinia)

// 引入路由
import router from './router'
// import wsDialog from './assets/wsDialog'
import measure from '../../libs-nodejs/east-measure'
// 引入全局css样式
import './assets/css/common.css'

/** 图片懒加载功能 */
import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload, {
  preLoad: 1.3,
  attempt: 1
})

/** UI样式库 */
Vue.use(Vuetify)
Vue.use(ElementUI)
Vue.use(Avue)
Vue.config.productionTip = false

/** 构建应用 */
import App from './frame/appIndex.vue'
new Vue({
  router,
  pinia,
  vuetify: new Vuetify({
    icons: {
      iconfont: 'mdi'
    },
  }),
  render: h => h(App)
}).$mount('#app')

/** 全局注入 */
// 将moment、measure注入vue
Vue.prototype.$moment = moment;
Vue.prototype.$measure = measure
// 将axios注入window
window.axios = axios

// 实时订阅初始化
import c_wsBookClient from './assets/wsBookClient'
window.realtimeTag = new c_wsBookClient('/api/realtime_tag')
window.realtimeIns = new c_wsBookClient('/api/realtime_ins')
window.realtimeEdge = new c_wsBookClient('/api/realtime_edge')
