module.exports = {
    show: { zh: '倍率' },
    units: {
        p: {
            show: { zh: '比例' },
            sign: '--',
            a: 1
        },
        pc: {
            show: { zh: '百分数' },
            sign: '%',
            a: 0.01
        },
        pm: {
            show: { zh: '千分数' },
            sign: '‰',
            a: 0.001
        },
    }
}