<template>
  <v-dialog v-model="show" persistent :max-width="ctrlInfo.corePlantLinkEnable ? '850px' : '450px'"
    :fullscreen="!isBigScreen">
    <!-- 创建系统管理员账号 -->
    <v-card v-if="firstUser">
      <v-card-title>欢迎使用！</v-card-title>
      <v-card-text>
        <!-- 错误提示信息 -->
        <v-alert v-if="error" dense type="error">{{ error.message }}</v-alert>
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>创建系统管理员账号</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-text-field label="用户名" v-model="firstUser.username" required @focus="clearError()"
              @keyup.enter="$refs.f_password.focus()"></v-text-field>
          </v-list-item>
          <v-list-item>
            <v-text-field label="密码" type="password" ref="f_password" v-model="firstUser.password" required
              @focus="clearError()" @keyup.enter="$refs.f_password2.focus()"></v-text-field>
          </v-list-item>
          <v-list-item>
            <v-text-field label="重复密码" type="password" ref="f_password2" v-model="firstUser.password2" required
              @focus="clearError()" @keyup.enter="$refs.f_password2.focus()"></v-text-field>
          </v-list-item>
          <v-list-item>
            <v-btn color="primary" @click="firstUserCommit()"> 创建 </v-btn>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
    <!-- 一般登录界面 -->
    <v-card v-else-if="!account.id">
      <v-card-title>登 录</v-card-title>
      <v-card-text>
        <!-- 错误提示信息 -->
        <v-alert v-if="error" dense type="error">{{ error.message }}</v-alert>
        <!-- 登录信息 -->
        <v-container v-if="!account.id">
          <v-row>
            <!-- 账号密码登录及选项 -->
            <v-col>
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>账号密码登录</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-text-field label="用户名" v-model="form.username" required @focus="clearError()"
                    @keyup.enter="$refs.password.focus()"></v-text-field>
                </v-list-item>
                <v-list-item>
                  <v-text-field label="密码" type="password" ref="password" v-model="form.password" required
                    @focus="clearError()" @keyup.enter="
                      $refs.password.blur()
                    $refs.btnlogin.$emit('click')
                      "></v-text-field>
                </v-list-item>
                <!-- 自动登录凭据授权时间，仅浏览器凭据可选 -->
                <v-list-item v-if="false">
                  <v-list-item-content>
                    <v-list-item-title>自动登录</v-list-item-title>
                    <v-radio-group v-model="form.browserLinkDays" row>
                      <v-radio label="本次" :value="0"></v-radio>
                      <v-radio label="7天" :value="7"></v-radio>
                      <v-radio label="31天" :value="31"></v-radio>
                    </v-radio-group>
                  </v-list-item-content>
                </v-list-item>
                <!-- 登录按钮 -->
                <v-list-item>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" ref="btnlogin" @click="btnLogin()"> 登录 </v-btn>
                </v-list-item>
              </v-list>
              <v-list v-if="false">
                <v-subheader>自动登录凭据</v-subheader>
                <v-list-item v-for="link in {}" :key="link.accountInfo.id">
                  <v-list-item-content>
                    <v-list-item-title>{{ link.accountInfo.username }}</v-list-item-title>
                    <v-list-item-subtitle>ID: {{ link.accountInfo.id }}</v-list-item-subtitle>
                    <v-list-item-subtitle>有效期至: {{ utils.dateFmt(link.tsDead) }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn>登入</v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-col>
            <!-- 微信扫码登录，仅大屏、且有core连接 -->
            <v-col v-if="isBigScreen && ctrlInfo.corePlantLinkEnable">
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>微信扫码登录</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <wechat-qr-code qrApiUrl="/api/account/wechatLoginQr"></wechat-qr-code>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
    <!-- 已登录状态 -->
    <v-card v-else>
      <v-card-title>
        <span class="headline">登录账号信息</span>
        <v-spacer></v-spacer>
        <!-- 关闭按钮，仅登录后显示 -->
        <v-icon color="blue darken-1" @click="show = false"> mdi-close </v-icon>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <!-- 账号信息 -->
            <v-col>
              <v-list>
                <v-subheader>
                  <span>当前登录账号</span>
                  <v-spacer></v-spacer>
                  <v-btn ref="btnlogout" @click="btnLogout()"> 退出登录 </v-btn>
                </v-subheader>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>账号ID</v-list-item-title>
                    {{ account.id }}
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>用户名</v-list-item-title>
                    {{ account.username }}
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <!-- 关联信息，仅大屏端显示 -->
            <v-col v-if="isBigScreen && ctrlInfo.corePlantLinkEnable">
              <v-list>
                <v-subheader>关联凭据</v-subheader>
                <v-list-item v-if="!linkInfo">
                  <v-list-item-content> 加载中…… </v-list-item-content>
                </v-list-item>
                <v-list-item v-else-if="!linkInfo.ok">
                  <v-list-item-content>
                    <v-list-item-title>加载错误</v-list-item-title>
                    请调试模式处理
                  </v-list-item-content>
                </v-list-item>
                <template v-else>
                  <!-- ===================== 微信公众号 ======================== -->
                  <v-list-item>
                    <span style="font-size: 120%; "> 微信公众号 </span>
                    <template v-if="linkInfo.wechatOffiaccunt">
                      <span style="color: green;">（已关联）</span>
                      <a @click="wechatUnlink()">解除关联</a>
                      <!-- 微信取消关联弹框 -->
                      <v-dialog v-model="wechatUnlinkDialog" persistent max-width="290">
                        <v-card>
                          <v-card-title class="text-h5"> 解除账号与微信的关联 </v-card-title>
                          <v-card-text>
                            <p><b>仅当出现以下情况时，应解除关联：</b></p>
                            <p>
                              1、无法扫码登录；<br />
                              2、不确定当前关联微信是谁的；<br />
                              3、关联的微信不再使用。<br />
                            </p>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="green darken-1" text @click="wechatUnlinkDialog = false"> 取消 </v-btn>
                            <v-btn color="red darken-1" text @click="wechatUnlink(true)"> 确认解除 </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </template>
                    <template v-else>
                      <span style="color: red;">（未关联，可“微信”扫码）</span>
                    </template>
                  </v-list-item>
                  <!-- 功能说明 -->
                  <v-list-item>
                    <ul>
                      <li>Web端扫码登录</li>
                      <li>微信公众号 - 自动登录</li>
                      <li>微信公众号 - 消息推送</li>
                    </ul>
                  </v-list-item>
                  <!-- 扫码关联 -->
                  <v-list-item v-if="!linkInfo.wechatOffiaccunt">
                    <wechat-qr-code qrApiUrl="/api/account/wechatLinkQr"></wechat-qr-code>
                  </v-list-item>

                  <!-- ===================== 手机号 ======================== -->
                  <v-list-item>
                    <span style="font-size: 120%; "> 手机号 </span>
                    <span style="color: gray;">（未关联，暂未实现）</span>
                  </v-list-item>
                  <!-- ===================== 电子邮件 ======================== -->
                  <v-list-item>
                    <span style="font-size: 120%; "> 电子邮件 </span>
                    <span style="color: gray;">（未关联，暂未实现）</span>
                  </v-list-item>
                </template>
              </v-list>
            </v-col>
          </v-row>
        </v-container>
        <!-- 辅助登录凭据列表，可用于登录、切换账号 -->
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import utils from '../assets/utils'
import { mapState } from 'pinia'
import { useSessionStore } from '@/store/session'
import { useSysStore } from '@/store/sys'
import WechatQrCode from '../components/dom/wechatQrCode.vue'

export default {
  components: { WechatQrCode },
  computed: {
    ...mapState(useSessionStore, ['isBigScreen', 'account', 'accountNotice']),
    ...mapState(useSysStore, ['ctrlInfo']),
  },
  props: {
    // 外部点击登录弹窗的按钮
    clickEvt: Number,
  },
  data() {
    return {
      utils,
      // 是否显示弹窗
      show: false,
      // 错误信息
      error: null,
      // 登录表单项，在formReset方法中定义各项
      form: null,
      // 关联信息
      linkInfo: null,
      // 微信取消关联弹框
      wechatUnlinkDialog: false,
      // 初始用户创建对象，非null时允许创建
      firstUser: null,
    }
  },
  beforeMount() {
    this.formReset()
  },
  mounted() {
    // 未登录状态，查询当前是否可以创建初始账号，请求后台判定是否初始创建账号
    if (!this.account.id) {
      axios.get('/api/account/firstUser').then(({ error, data }) => {
        if (error) return error.alert()
        // 允许创建初始用户，写this.firstUser项目
        if (data.allow) {
          this.firstUser = {
            username: '',
            password: '',
            password2: '',
          }
        }
      })
    }
  },
  watch: {
    // 用户点击，弹窗
    clickEvt() {
      this.show = true
    },
    // 用户信息更新
    account: {
      immediate: true,
      handler: function (to) {
        // 若未登录，强制弹窗
        this.show = !to.id
        // 更新用户关联信息
        if (this.ctrlInfo.corePlantLinkEnable) this.renewLinkInfo()
      }
    },
    // 控制信息变更
    ctrlInfo: {
      deep: true,
      handler(to, from) {
        // 与核心平台连接配置变化时，重载账号连接信息
        if (from.corePlantLinkEnable !== to.corePlantLinkEnable) {
          this.renewLinkInfo()
        }
      }
    },
    // ws事件监听
    accountNotice(event) {
      // 微信与账号关联，刷新关联信息
      if (event.topic === 'wechatLinkChange') this.renewLinkInfo()
    },
  },
  methods: {
    // 登录（账号密码）
    async btnLogin() {
      // 调用后台接口
      const { error } = await axios.post('/api/account/login', this.form)
      // 有错误，提示
      if (error) {
        this.error = error
      }
      // 无错误表明登录成功，跳转等其他响应，依靠ws推送信息
      else {
        // 重置登录表单
        this.formReset()
      }
    },
    // 退出登录
    async btnLogout() {
      // 调用后台接口
      const { error } = await axios.get('/api/account/logout')
      // 有错误，提示
      if (error) {
        this.error = error
      }
    },
    // 清除错误信息
    clearError() {
      this.error = null
    },
    // 表格重置
    formReset() {
      this.form = {
        username: '',
        password: '',
        browserLinkDays: 0,
      }
    },
    // 更新关联信息
    async renewLinkInfo() {
      // 先清空记录
      this.linkInfo = null
      // 不用继续的情况
      if (
        !this.account.id // 当前未登录
      ) return
      // 调用后台请求，若出错则弹窗
      const { error, data } = await axios.get('/api/account/linkInfo')
      if (error) {
        error.alert()
        return
      }

      // 在数据中添加完成标识
      data.ok = true

      // 记录信息
      this.linkInfo = data
    },
    // 微信解除关联
    async wechatUnlink(confirmed) {
      // 未确认时，弹窗
      if (!confirmed) {
        this.wechatUnlinkDialog = true
        return
      }

      // 确认后，调用API
      const { error } = await axios.get('/api/account/wechatLinkDel')
      if (error) error.alert()
      else {
        this.wechatUnlinkDialog = false
        this.linkInfo = null
      }
    },
    // 创建初始用户
    async firstUserCommit() {
      if (this.firstUser.password !== this.firstUser.password2) {
        this.error = { message: '两次键入密码不一致' }
        return
      }
      // 调用后台
      const { error } = await axios.post('/api/account/firstUser', {
        username: this.firstUser.username,
        password: this.firstUser.password,
      })
      // 有错误，提示
      if (error) this.error = error
      // 没有错误，清空firstUser对象
      else this.firstUser = null
    },
  },
}
</script>
